import * as React from "react";
import { PrimaryButton, Icon } from "@fluentui/react";


const FirstRun = () => {
  
  const click = () => {
    localStorage.setItem('wrwx/wrangles/shownFirstRun', 'true');
    location.reload();
  }
  
  return(
    <div className="ms-welcome">
      <section className="ms-welcome__header">
        <br/>
        <img width="220" src={"/assets/logos/WranglesXL-blue-220x46.png"} />
        <br/>
      </section>
      <main className="ms-welcome__main">
        <h2 className="ms-font-l">
        ‘Wrangles’ are a range of easy but powerful transformations for data cleaning and enrichment.
        </h2>
        <br/>
        <ul style={{width:'98%'}} className="ms-List ms-welcome__features">
          <li className="ms-ListItem">
            <Icon iconName='D365TalentLearn' styles={{root: {marginRight: 10}}}/>
            <span className="ms-font-m">
              Go to <a href='/help.html'><b>How To Wrangle</b></a> to learn more.
            </span>
          </li>
          <li className="ms-ListItem">
            <Icon iconName='LightningBolt' styles={{root: {marginRight: 10}}}/>
            <span className="ms-font-m">
              Use our range of wrangles to <b>Extract</b>
              , <b>Format</b>, <b>Classify</b> or <b>Translate</b> your data.
            </span>
          </li>
          <li className="ms-ListItem">
            <Icon iconName='WebAppBuilderFragment' styles={{root: {marginRight: 10}}}/>
            <span className="ms-font-m">
              Train and manage your own Wrangles on the <b>My Wrangles</b> task pane.
            </span>
          </li>
        </ul>
        <br/>
        <PrimaryButton className="ms-welcome__action" onClick={click}>
          Click here to get Started
        </PrimaryButton>
      </main>
    </div>
  );
  
}

export default FirstRun;